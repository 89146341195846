 .Navbar {
     position: relative;
     width: 100%;
     display: flex;
     flex-direction: column;
     align-items: center;
     /* background-image: url('../photos//pngwing.com.png');
 
  
 justify-content: center; */

     background: rgb(12, 12, 12);
     background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);
     /* 2 */
     /* border-bottom: 2px solid black; */

 }

 @media screen and (max-width:1000px) {
     .navbar {
         width: 100%
     }

     .navRight {
         width: -webkit-fill-available;

     }

 }

 .navbar-nav .nav-item .nav-link {
     color: white;
     font-size: larger;
     font-weight: 600;
 }
@media screen and (max-width:800px){
 .navbar-nav .nav-item .nav-link {
    color: black;
 }
}
 .navbar-nav .nav-item {
     font-size: large;
     color: rgb(45, 43, 43);
     text-decoration: none;
     position: relative;
     color: #000;
 }


 .navbar-nav .nav-item :hover {
     color: #000;
 }

 .navbar-nav .nav-item::before {
     content: "";
     position: absolute;
     display: block;
     width: 100%;
     height: 2px;
     bottom: 0;
     left: 0;
     background-color: #000;
     transform: scaleX(0);
     transition: transform 0.3s ease;
 }

 .navbar-nav .nav-item:hover::before {
     transform: scaleX(1);

 }

 .navLeft img {
     height: 100%;
     width: 100%;
     border-radius: 10px;
     border-bottom: 5px solid rgba(36, 36, 132, 0.824);
     border-top: 5px solid rgba(36, 36, 132, 0.824);
     
   
 }

 .navRight {
     width: '15%';
     height: fit-content;
     display: flex;
     justify-content: center;
     align-items: center;

 }

 

 .navRight ul {
     display: flex;

     width: 100%;
     height: 100%;
     margin: auto;
     margin-left: 0;
     padding-inline-start: 0;
     justify-content: space-around;
 }

 .navRight ul li {
     list-style-type: none;
 }

 .navRight ul li a {

     font-size: larger;

background-color: #461c95;

     padding: 3px 6px;
     display: block;
     border-radius: 50%;

     text-align: center;



 }

 .navRight ul li a .icon {
     /* color: rgb(116, 86, 226); */
     transition: 0.5s;


 }

 .navRight ul li a:hover .icon {
     /* color: white; */

     color: rgb(69, 15, 119);
     transform: rotateX(360deg);
 }

 /* .navRight ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
   
    width: 100%;
    height: 100%;
    transition: 0.5s;
    z-index: 3;
} */
 .navRight ul li .fa-brands {
      
     border-radius: 50%;
     color: white;
     padding: 4px;
 }

 .navRight ul li a:hover {
     /* background-color: blue; */
     background-color: rgb(252, 252, 255);

 }



 @media screen and (max-width:800px){
     
 }