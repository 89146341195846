.Home2{
    display: grid;
    place-items: center;
}
.Home2 .slider{
    padding: 30px 0;
    margin: auto;
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    width: 100%;
}
.Home2 .slider .slide-track{
    display: flex;
    width: calc(250px * 18);
    animation: scroll 20s linear infinite;
}
.Home2 .slider .slide-track:hover{
    animation-play-state:paused ;
}
@keyframes scroll {
    0%{
        transform:translateX(0);
}
    100%{
        transform: translateX(calc(-250px*9));

    }
}
.Home2 .slider .slide-track .slide{
    height: 150px;
    width: 200px;
    display: flex;
    align-items: center;
    padding: 15px;
    perspective: 100px;
}
.Home2 .slider .slide-track .slide  img{
    width: 100%;
    height: 100%;
    transition: transform 0.5s;
 object-fit: contain;

}
.Home2 .slider .slide-track .slide  img:hover{
    transform: translateZ(10px);
}
 