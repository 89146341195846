.travelBanner {
    position: relative;
}
.travelBanner p{
    color: white;
    text-align: center;
}
 .travel h1{
    font-size: 70px;
    font-weight: 700;
 }
.travelBanner .travelBannerContent {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(1, 1, 1, 0.243);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.travelBanner .travelBannerImg img {
    width: 100%;
    height: auto;
}

.travelContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.travelContent .travelContentImg {
    display: flex;
    height: 302px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.travelContent .travelContentImg img {
    width: auto;
    height: 100%;

}


   p {
    font-size: larger;
}
.travelPdfs{
    text-align: center;
}
.travelPdfs .row{
    margin-top: 50px;    
}
 
.travelPdfs .card .pdfsImg{
    height: 300px;
    transition: 0.4s;
    overflow: hidden;
}
.travelPdfs a{
    text-decoration: none;
    color: black;
}
.travelPdfs .card{
    position: relative;
   
}
.travelPdfs .card .pdfsTitle{
    padding: 8px 0;
    transition: 0.4s;
    background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);
}
.travelPdfs .card .pdfsTitle h4{
    text-align: center;
    margin: 0;
color: white;
}
.travelPdfs .card img{
    height: 100%;
    width: 100%;
    transition: 0.4s;
}

.travelPdfs .openPdf{
    position: absolute;
    top: -10%;
    opacity: 0;width: 100%;
    display: flex;
    justify-content: center;
    color: white;
 transition: 0.4s;
 align-items: center;
}
.travelPdfs .openPdf span{
    font-weight: bold;
}
.travelPdfs .openPdf i{
    margin-left: 10px;
}
.travelPdfs .card:hover .openPdf{
opacity: 1;
top: 45%;
transform: scaleY(-10deg);
}
.travelPdfs .card:hover .pdfsTitle{
    background: linear-gradient(90deg, rgb(140, 96, 227) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);

}
.travelPdfs .card:hover img{
    transform: scale(1.1);
    filter: brightness(40%);
}
 .border {
    margin: 50px 0;
 }
 .travelPdfs video{
    left:0
 }
 .travelPdfs .card img{

    object-fit: cover;
}
 .travelPhotoVideo i{
    font-size: xx-large;
 }
 .travelPhotoVideo {
    margin-top: 50px;
 }
 .travel h2{
    font-weight: 700;
 }
 .travelPdfs .card{
    border-radius: 20px;
    overflow: hidden;
 }
 @media screen and (max-width:1000px) {
    .travel h1{
        font-size: xx-large;
    }
 .travelContents   .travelContent{
    flex-direction: column;
 }
 }
 @media screen and (max-width:767px) {
    .travelPdfs .card {
        margin: 20px 0;
        border-radius: 10px;
    }
    .travelContent .travelContentImg img{
        height: auto;
        width: 100%;
    }
    .travelBanner {
        height: 50vh;
    }
    .travelBanner .travelBannerImg  img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
   
 }