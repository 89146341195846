.Project {
  padding: 50px 10px 50px 10px;
}

.Project .Project6 {
  height: auto;
}

.Project .projectHead {
  padding: 80px 0 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.Project .projectHead span {
  font-size: 40px;
  font-weight: 600;
  color: rgb(90, 86, 86);
}
 
.Project .Project6 .ProjectSub6 {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 20px;
  color: #000;
  margin: 0;
  padding: 0;
}

.Project .Project6 .ProjectSub6 .swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.Project .Project6 .ProjectSub6 .swiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 400px;
  height: 400px;
}

.Project .Project6 .ProjectSub6 .swiper .swiper-slide img {
  display: block;
  width: 100%;
}