 /* home7 */
  .home7 {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 100px;
    background-color: #d8d0e7;
    margin-top: 100px;
  }
 
   .home7 span {
    font-size: 200px;
    font-weight: 400;
    color: #ffffff;
    font-family: cursive;
 
  }
 
   .home7 .home7downArrow {
    margin-top: 0px;
    height: 100px;
    width: 100px;
 border-radius:50%;
 overflow: hidden;
 
  }
 
   .home7 .home7downArrow img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 
  }
 
   .home7 .carousel {
    width: 60%;
    height: 90vh;
   
 
 
  }
 
   .home7 .carousel .carousel-inner {
    width: 100%;
    height: 100%;
 
  }
 
   .home7 .carousel .carousel-inner .carousel-item {
    width: 100%;
    height: 100%;
 border-radius: 20px;
 overflow: hidden;
  }
 
   .home7 .carousel .carousel-inner .carousel-item img {
    width: 100%;
    height: 100%;
 object-fit: cover;
  }
 
  @media screen and (max-width: 800px) {
     .home7 {
      height: 100%;
      justify-content: space-between;
    }
 
     .home7 span {
      font-size: 50px;
 
    }
 
     .home7 .carousel {
      width: 90%;
      height:400px;
      margin-top: 20px;
 
  
    }
 
  }