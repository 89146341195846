 h1{
 font-size:xxx-large;
 font-weight: bolder;
 }
 .title{
  color: #A33E00;
  font-weight: bolder;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

 }
 p{
  text-align: justify;
  color: crimson;
  font-size: larger;
 }
span{
  text-align: justify;
}
 .Home .home1 { 
   height: 100vh;
   background-color: #000000;
   /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
 }

 .Home .home1 .carousel {
   width: 100%;
   height: 100%;
 }

 .Home .home1 .carousel .carousel-inner {
   width: 100%;
   height: 100%;
 }

 .Home .home1 .carousel .carousel-inner .carousel-item {
   width: 100%;
   height: 100%;
 }

 .Home .home1 .carousel .carousel-inner .carousel-item img {
   width: 100%;
   height: 100%;
   object-fit: cover;
 }


 @media screen and (max-width: 800px) {
   .Home .home1 {
     height:auto;
   }
   .Home .home1 .carousel {
    height: 400px;
   }
 }

 .Home .home1 .tit1 {
   font-size: 200px;
   font-weight: 900;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
 }
h1, h2, h3, h4, h5{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
font-weight: bolder;
}
 .marquee-container {
   margin-top: 70px;
   height: 150px;
   overflow: hidden;
   line-height: 30px;
 }

 .marquee {
   margin-left: 70%;
   padding-left: 100px;
   height: 100%;
   width: auto;
   overflow: hidden;
   position: absolute;
   white-space: nowrap;
   animation: marquee 10s linear infinite;
 }

 .marquee .main {
   width: 200px;
   height: 100%;
 }

 .marquee .main img {
   width: 100%;
   height: 100%;
 }

 @keyframes marquee {
   from {
     left: 0%;
   }

   to {
     left: -100%;
   }
 }

 .home2 .main img {
   width: 100%;
   height: 100%;
 }

 .Home .home3 {

   width: fit-content;
   display: flex;
   display: contents;

   justify-content: center;
   align-items: center;

 }
.travelPhotoVideo .btn{
  font-weight: 600;
  font-size: larger;
  text-transform: capitalize;
}
 .Home .home3 .home3Title {
   display: flex;
 
   justify-content: center;
   align-items: center;
   width: 100%;
   /* box-shadow: 2px 2px 10px rgb(14, 10, 3); */



   appearance: none;

   /* border-radius: 40em; */
   border-style: none;
   /* box-shadow: #ADCFFF 0 -12px 6px inset; */

   color: #000000;
   cursor: pointer;
   display: inline-block;
   font-family: -apple-system, sans-serif;


   letter-spacing: -.24px;


   outline: none;
   padding: 1rem 1rem;
   quotes: auto;
   text-align: center;
   text-decoration: none;
   transition: all .15s;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
 }

 .Home .home3 .home3Title:hover {
   /* background-color: #FFC229; */
   /* box-shadow: #FF6314 0 -6px 8px inset; */
   transform: scale(1.125);
 }

 .Home .home3 .home3Title:active {
   transform: scale(1.025);
 }



 .Home .home3 .home3Title .title {
   display: flex;
   justify-content: center;
   width: 100%;
 }
.Home .home3 .shortInfoHome3 {

   display: flex;
   justify-content: center;
   margin: 0px 0px 20px 0px;


 }

 @media screen and (max-width: 800px) {
   .Home .home3 .home3Title .title {
     width: 100%;
     margin: 0;
     padding: 0;
   }

   .Home .home3 .home3Title .title span {
     font-size: x-large;
     width: 100%;
     padding: 0;
   }
 }

 @media screen and (max-width: 800px) {

   .Home .home3 .shortInfoHome3 {
     display: contents;
     width: 100%;
   }

   .Home .home3 .shortInfoHome3 p {
     width: 100%;
     font-size: small;

   }
 }

 .shortInfoHome3 p {
   text-align: center;
   display: block;
   justify-content: center;

 }






 /* home4 card */

 .home4 {
   box-sizing: border-box;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;

   margin: 100px 0px ;
 }

 .home4 .wrapper {

   margin: 10px 0px 30px 0px;
   display: flex;
   width: 90%;
   justify-content: space-around;
 }

 .home4 .wrapper .FullCard {
   width: 280px;
   height: 360px;
 }

 .home4 .wrapper .card {
   width: 100%;
   height: 100%;
   border-radius: 15px;

   background: white;
   position: relative;
   display: flex;
   align-items: flex-end;
   transition: 0.4s ease-out;
   box-shadow: 0px 7px 10px rgba(black, 0.5);
 }

 .home4 .wrapper .card:hover {
   transform: translateY(20px);
 }

 .home4 .wrapper:hover:before {
   opacity: 1;
 }

 .home4 .wrapper .card:hover .info {
   opacity: 1;
   transform: translateY(0px);
 }

 .home4 .wrapper .card:hover:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   display: block;
   width: 100%;
   height: 100%;
   border-radius: 15px;
   background: rgba(black, 0.6);
   z-index: 2;
   transition: 0.5s;
   opacity: 0;
 }

 .home4 .wrapper .card img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   position: absolute;
   top: 0;
   left: 0;
   border-radius: 15px;
 }

 .home4 .wrapper .card .info {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   text-align: center;
   position: relative;
   z-index: 3;
   border-radius: 15px;
   color: rgb(61, 57, 57);
   opacity: 0;
   width: 100%;
   height: 100%;
   background: rgba(255, 255, 255, 0.2);
   backdrop-filter: blur(15px);
   transform: translateY(30px);
   transition: 0.5s;
   padding: 12px;
 }

 .home4 .wrapper .card .info h1 {
   margin: 0px;
 }

 .info p {
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
   letter-spacing: 1px;
   font-size: 15px;
   margin-top: 8px;
 }

 .home4 .wrapper .card .info button {
   padding: 0.6rem;
   outline: none;
   border: none;
   border-radius: 3px;
   background: white;
   color: black;
   font-weight: bold;
   cursor: pointer;
   transition: 0.4s ease;
 }

 .home4 .wrapper .card .info button:hover {
   background: dodgerblue;
   color: white;
 }

 @media screen and (max-width: 800px) {
   .home4 .wrapper {
     flex-direction: column;
   }

   .home4 .wrapper .FullCard {
     margin-bottom: 100px;
   }

   .home4 .wrapper {
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
   }
 }

 @media screen  and (max-width:767px){
  .home4 {
  margin: 0;
 }
 .home4 .wrapper{
  margin: 0;
 }
 }


 /* home 5 gallary button */
 /* CSS */
 .Home .home5 {
   background-color: rgb(255, 255, 255);
   width: 100%;
padding-top: 100px;
   display: flex;
   justify-content: space-between;
   flex-direction: column;
   align-items: center;
 }

 .Home .home5 img {
  border-radius: 10px;
     border-bottom: 10px solid rgba(36, 36, 132, 0.824);
   width: 80%;

 }
@media screen and (max-width:800px) {
  .Home .home5 img{
    height: auto;
    width: 98%;
  }
  
}

 .home5 .home5GallaryBtn {
   width: 20%;
   appearance: none;
   background-color: #846ff1;
   border-radius: 50px;
   border: 1px solid black;
   /* box-shadow: #4b45bf 0 -12px 6px inset; */
   box-sizing: border-box;
   color: #ffffff;
   cursor: pointer;
   display: inline-block;
   font-family: -apple-system, sans-serif;
   font-size: 2.2rem;
   font-weight: 700;
   letter-spacing: -.24px;

   margin: 0;
   outline: none;
   padding: 1rem 1.3rem;
   quotes: auto;
   text-align: center;
   text-decoration: none;
   transition: all .15s;
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
 }

 .home5GallaryBtn:hover {
   background-color: #fefefe;
   box-shadow: #9e9bd5 0 -6px 8px inset;
   transform: scale(1.05);
   color: #6715f5;
 }

 .home5GallaryBtn:active {
   transform: scale(0.1);
 }

 @media (min-width: 768px) {
   .home5GallaryBtn {
     font-size: 1rem;
     padding: 0.5rem 2rem;
   }
 }

 @media (min-width: 375px) {
   .home5GallaryBtn {
     font-size: 1rem;
     padding: 0.2rem 1.5rem;
     width: 100%;

   }
 }
 @media screen  and (max-width:767px){
  .Home .home5{
    padding-top: 0;
  }
 }
 /*  home 5 button css  */
 .home5 {

   width: 100%;
   /* background-image: url(../photos/126945-gallery-loader.gif);
  background-position: center;
  background-repeat: no-repeat; */
   display: flex;
   justify-content: center;
   align-items: center;
 


 }

 .Home .home5 .container {

   background: transparent;
   display: flex;
   flex-direction: column;

   align-items: center;
   justify-content: center;




 }

 .Home .home5 .container span {
   font-size: 50px;
   font-weight: 500;
   color: #767690;
   font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
 }



 .button-six {
   background: rgba(181, 122, 244, 0.984);
   color: black;
   font-size: xx-large;
   transition: 0.4s;

   transform: perspective(1px) translateZ(0);
   border-radius: 10px;
   box-shadow: 0px 5px 5px rgb(95, 81, 81);
 }

 .button-six:hover {
   color: white;
   box-shadow: 0 0 0 10px rgba(236, 227, 227, 0.283)
 }

 .button-six::before {
   clip-path: circle(0.5% at 50% 50%);
   background: #9900cc;
   transition: 0.4s;
   opacity: 1;
 }

 .button-six:hover::before {
   clip-path: circle(100% at 50% 50%);
 }



 /*  */


 button {

   padding: 10px 30px;
   margin: 10px;
   border: 0;
   cursor: pointer;
   color: black;
   text-transform: lowercase;
   border-radius: 10px;
   display: inline-block;
   overflow: hidden;
   transition: all 0.4s cubic-bezier(.86, .01, .15, .99);
 }

 button::before {
   content: "";
   position: absolute;
   z-index: -1;
   top: 0px;
   left: 0px;
   right: 0px;
   bottom: 0;
 }

 button.gradient:hover {
   color: white;
   box-shadow: 0 5px 35px rgba(0, 0, 0, 0.6);
   transform: translateY(-5px);
 }


 /* home 6 css */