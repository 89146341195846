* {
    margin: 0;
    padding: 0;
}

.eventHead {
    height: 100vh;
    width: 100%;


}

 video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute
}


.eventTit {
    text-align: center;
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    top: 0px;
    background-color: rgba(8, 8, 8, 0.345);
    display: flex;
    justify-content: center;
    align-items: center;
}
.Events .eventHead .eventTit span{
    font-size: 70px;
    font-weight: bolder;
    color: rgb(231, 224, 237);
}
.Events .eventHead2{
  
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;

}
.Events .eventHead2 .row{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Events .eventHead2 .row .col-6{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.Events .eventHead2 .ratio{
    width: 450px;
    height: 350px;
 
    /* border: 10px solid rgba(119, 46, 46, 0.868); */
    perspective: 100px;
    
} 
.Events .eventHead2 .ratio video {
    border-radius: 10px;
    border-bottom: 10px solid rgba(36, 36, 132, 0.824);
    border-top: 10px solid rgba(36, 36, 132, 0.824);
    
    transition: transform 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
 
}
.Events .eventHead2 .ratio img{
    border-radius: 10px;
    border-bottom: 10px solid rgba(36, 36, 132, 0.824);
    border-top: 10px solid rgba(36, 36, 132, 0.824);
    
    transition: transform 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Events .eventHead2 .ratio video:hover{
    transform: translateZ(3px);
    box-shadow: 2px 2px 25px black;
}
.Events .eventHead2 .ratio img:hover{
    transform: translateZ(3px);
    box-shadow: 2px 2px 25px black;
}

@media screen and (max-width: 700px){
    .Events .eventHead2 .row{
        flex-direction: column;
    }
    .Events .eventHead2 .row .col-6{
        width: 100%;
    }
    .Events .eventHead2 .ratio{
        width: 95%;
       height: 100vh;
    }

}
    
