/* .AboutUs {
  margin-top: -20px;
} */

.AboutUs .aboutUs-1 {
  width: 100%;
  height: 70vh;
  background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  perspective: 100px;
}

.AboutUs .aboutUs-1 span {
  font-size: 80px;
  font-weight: 500;
  color: aliceblue;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.AboutUs .aboutUs-1 img {
  height: 65%;
  width: 40%;
  border-radius: 20px;
  transition: transform 0.5s;

}

.AboutUs .aboutUs-1 img:hover {
  transform: translateZ(10px);
}

@media screen and (max-width: 800px) {
  .AboutUs .aboutUs-1 {
    height: auto;
  }

  .AboutUs .aboutUs-1 span {
    font-size: xxx-large;
  }
}

.AboutUs .aboutUsSummary {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}


.AboutUs .aboutUsSummary .aboutUsSummary1 {
  margin: 100px  0;
  text-align: left;
  display: flex;
  justify-content: space-around;
  font-size: larger;
  flex-direction: row;
  height: auto;
  align-items: center;
   


}

.AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont {
  width: 40%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: cover;
}

.AboutUs .aboutUsSummary .aboutUsSummary1 span {
  width: 50%;
  text-align: justify;
}



.AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont .aboutUsSummary1-1 {
  height: 150px;
  width: 100%;
}

.AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont .aboutUsSummary1-1 img {
  height: 100%;
  width: 50%;
}

.AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont .aboutUsSummary1-2 img {
  height: 100%;
  width: 50%;
}

@media screen and (max-width: 800px) {
  .AboutUs .aboutUsSummary {
    width: 100%;
  }

  .AboutUs .aboutUsSummary .aboutUsSummary1 {
    flex-direction: column;
    width: 100%;

  }

  .AboutUs .aboutUsSummary .aboutUsSummary1 span {
    font-size: medium;
      display: contents;
  }

  .AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont {
    width: 100%;

  }
  .AboutUs .aboutUsSummary .aboutUsSummary1{
    margin: 20px 0;
  }
  .AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont .aboutUsSummary1-1 {
    margin: 10px 0;
  }
  .AboutUs .aboutUsSummary .aboutUsSummary1 .aboutUsSummaryImgCont .aboutUsSummary1-1 img{
    width: 100%;
  }
  
}

/* no progress */
.NoProgress {
  display: flex;
  justify-content: space-around;
  align-items: center;

}

.NoProgress .NoProgress1 {
  font-size: 100px;
  font-weight: bold;
  color: blueviolet;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

@media screen and (max-width: 800px) {
  .NoProgress {
    margin: 0;
    padding: 0;
    flex-direction: row;
    justify-content: space-around;
    margin: 50px 0;
  }

  .NoProgress .NoProgress1 {
    font-size: 40px;
    display: flex;
    align-items: center;

    justify-content: center;
  }

  .CountInfo a .countBtn {
    font-size: 22px;

  }
  .aboutUsSummary1{
    margin: 0;
  }

}

.CountInfo .countBtn {
  font-size: 32px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  padding: 0px 10px 0px 10px;
  background-color: rgb(176, 83, 223);
  border: 0px solid grey;
  box-shadow: 2px 5px 15px grey;

}

.CountInfo .countBtn:hover {
  color: rgb(176, 83, 223);
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 5px 25px grey;
}