.Footer{
    height: 100%;

}
 .Footer p{
   color: white;
 }
.Footer .upperFooter{
   padding: 50px 0 50px 0;
    height: fit-content;
    width: 100%;
    background-color: rgb(163, 62, 0);;
    justify-content: space-between;
    align-items: center;
    color: aliceblue;
}
.Footer .upperFooter .row{
    height: 100%;
}
.Footer .upperFooter .row .commentFooter{
    display: flex;
   
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.upperFooter .row {
   margin: 0;
}
.Footer .upperFooter .row h3{
   margin-bottom: 12px ;
}
.Footer .lowerFooter{
   height: auto;
   
    width: 100%;
    color: white;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0 20px 0;
}




 
.contactFooter {
    width: 100%;
    height: 100vh;
    color: #fff;
    /* display: flex;
     justify-content: center;
     align-items: center; */
  
 
 
 }
 .contactFooter .row{
    justify-content: center;
 }
 .contactFooter .form{
    width: -webkit-fill-available;
    display: inline-grid;
    justify-content: center;
    align-items: center;

 }
 
 
 .contactFooter-form {
    display: flex;
    align-items: center;
    justify-content: center;
   
 }
 .row .footer{
   height: 50%;
 }
 .contactFooter-form label {
    display: block;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: normal;
    font-style: italic;
    margin-bottom: 2px;

 }

 .contactFooter-form .col {

    display: flex;

 }

 .contactFooter-form .col input {
    width: 100%;
 
 }


 .contactFooter-form input {
    width: 100%;
    margin: 0px auto;

    background: #fff;
    border: 0px solid black;
    border-radius: 5px;
    outline: none;

 }

 .fa {
    margin-right: 10px;
 }

 .singleItem {
    margin: 10px 0px;
    
 }
 .contactFooter-form .col .singleItem{
   width: 75%;
}
 .textArea  textarea {
    width: 100%;
    outline: none;
    border: 0;
    border-radius: 10px;
    background: #fff;
    padding: 10px;
   
 }

 




 /* CSS */
 .button-73 {
    appearance: none;
    background-color: #FFFFFF;
    border-radius: 40em;
    border-style: none;
    box-shadow: #ADCFFF 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -.24px;
   
    margin: 0;
    outline: none;
    padding: 1rem 1.3rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
 }

 .button-73:hover {
    background-color: #FFC229;
    box-shadow: #FF6314 0 -6px 8px inset;
    transform: scale(1.125);
 }

 .button-73:active {
    transform: scale(1.025);
 }

 @media (min-width: 768px) {
    .button-73 {
      font-size: 1rem;
      padding: 0.5rem 2rem;
    }
 }
 @media (min-width: 375px) {
   .button-73 { 
     font-size: 1rem;
     padding: 0.2rem 1.5rem;
     width: 100%;
     
   }
}

.Footer .btn-primary{
   width: 100%;
   margin: 0;
}

 .msg {
    border-radius: 10px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    letter-spacing: 0.1rem;
    font-style: italic;
    color: rgb(229, 61, 0);
    font-weight: 600;
    background-color: #fff;
    display: none;
 }


 .contactFooter-form input:hover,
   .textArea  textarea:hover {
   background-color: #ffffff;
   box-shadow: #d3cbc7 0 -6px 8px inset;
    /* transform: scale(1.125); */
 
   }
  .textArea  textarea:active{
      transform: scale(1.025);
   }
 .contactFooter-form input {
    padding: 10px;
 }
@media screen  and (max-width:767px){
   .Footer .col-md-4{
      margin: 20px 0;
   }
   .Footer .col-md-4 form{
      width: 100%;
   }
   .Footer .lowerFooter span{
      text-align: center;
      margin: 10px;
   }
}