@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Bree+Serif&family=EB+Garamond:ital,wght@0,500;1,800&display=swap');


.MembershipCards {
    background: #ffffff;


    /* background-image: linear-gradient(to right, #ffffb3, #ffe6e6); */
    background-attachment: fixed;
    background-size: cover;
    height: auto;
    padding: 0px 0px 100px 0px;


}

.MembershipCards .LatestProject {

    padding-top: 100px;

    background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);
}

.MembershipCards .LatestProject span {
    font-size: 100px;
    font-weight: 900;
    color: #fbfcff;

}

@media screen and (max-width:700px) {
    .MembershipCards .LatestProject span {
        font-size: 30px;
    }
}

.MembershipCards .row {
    
    padding-top: 60px;
    display: flex;
    justify-content: center;
    background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);



}

.MembershipCards .row .col-6 {
    height: 350px;
    width: 500px;
    padding: 20px;
}

.MembershipCards .row .col-6 #container {
    box-shadow: 0 15px 30px 1px grey;
    background: rgba(255, 255, 255, 0.90);
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.MembershipCards #container .product-details {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding: 10px;
    height: 100%;
    float: left;
    width: 40%;
}

.MembershipCards #container .product-details h1 {
    font-family: 'Bebas Neue', cursive;
    display: inline-block;
    position: relative;
    font-size: xx-large;
    color: #344055;
    margin: 0;
}

.MembershipCards #container .product-details h1:before {
    position: absolute;
    content: '';
    right: 0%;
    top: 0%;
    transform: translate(25px, -15px);
    font-family: 'Bree Serif', serif;
    display: inline-block;
    background: #ffe6e6;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
    color: white;
    margin: 0;
    animation: chan-sh 6s ease infinite;

}






.MembershipCards #container .product-details .hint-star {
    display: inline-block;
    margin-left: 0.5em;
    color: gold;
    width: 100%;
}


.MembershipCards #container .product-details>p {
    font-family: 'EB Garamond', serif;

    font-size: large;
    color: #7d7d7d;
    width: 100%;

}

.MembershipCards #container .product-details .control {
    position: absolute;
    /* bottom: 20%;
    left: 22.8%; */
    bottom: 0px;

}

.MembershipCards #container .product-details .control .btn {

    transform: translateY(0px);
    transition: 0.3s linear;
    background: #809fff;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    color: #ffffff;
    padding: 0;
    margin: 0;

}

.MembershipCards #container .product-details .control .btn:hover {
    transform: translateY(-6px);
    background: #1a66ff;
}

.MembershipCards #container .product-details .control .btn span {
    /* font-family: 'Farsan', cursive; */
    transition: transform 0.3s;
    display: inline-block;
    padding: 5px 10px;
    font-size: small;
    margin: 0;
    color: #FFF;

}

.MembershipCards #container .product-details .control .btn .price,
.shopping-cart {
    background: #333;
    border: 0;
    margin: 0;
}

.MembershipCards #container .product-details .control .btn .price {
    transform: translateX(-10%);
    /* padding-right: 15px; */
}

.btn .shopping-cart {
    transform: translateX(-100%);
    position: absolute;
    background: #333;
    z-index: 1;
    left: 0;
    top: 0;
}

.MembershipCards #container .product-details .control .btn .buy {
    z-index: 3;
    font-weight: bolder;
}

.MembershipCards #container .product-details .control .btn:hover .price {
    transform: translateX(-110%);
}

.MembershipCards #container .product-details .control .btn:hover .shopping-cart {
    transform: translateX(0%);
}



.MembershipCards #container .product-image {
    transition: all 0.3s ease-out;
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 100%;
    /* float: right; */
    width: 60%;
    display: inline-block;
}

.MembershipCards #container .product-image img {
    width: 100%;
    height: 100%;
}

.product-image .info {
    background: rgba(27, 26, 26, 0.9);
    /* font-family: 'Bree Serif', serif; */
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    position: absolute;
    line-height: 1.8;
    text-align: left;
    font-size: 80%;
    cursor: pointer;
    color: #FFF;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}

.MembershipCards #container .product-image .info h2 {
    text-align: center
}

.MembershipCards #container .product-image:hover .info {
    transform: translateX(0);
}

.MembershipCards #container.product-image .info ul li {
    transition: 0.3s ease;
}

.MembershipCards #container .product-image .info ul li:hover {
    transform: translateX(50px) scale(1.3);
}

.MembershipCards #container .product-image:hover img {
    transition: all 0.3s ease-out;
}

.MembershipCards #container .product-image:hover img {
    transform: scale(1.2, 1.2);
}

@media screen and (max-width: 800px) {


    .MembershipCards .row .col-6 #container {
        height: 300px;
    }

    .MembershipCards #container .product-details>p {

        font-size: small;
    }

    .MembershipCards #container .product-details h1 {
        font-size: x-large;
    }


}