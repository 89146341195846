.Contact{
  margin-top: -20px;
}
.Contact .contactImage{
    width: 100%;
    height: 70vh;
}
.Contact .contactImage img{
    width: 100%;
    height: 100%;
} 

.Contact .ConatactMid{
    display: flex;
    align-items: center;
    justify-content: space-between;

 

}
 

@media screen and (max-width:800px){
  .Contact .ConatactMid{

  flex-direction: column;
}
.contactBtn {
  border-radius: 0;
  width: 100%;

}
}
 /* CSS */
 .contactBtn {
    appearance: none;
    background-color: #8b7eff;
    border-radius: 10px;
    border-style: none;
    box-shadow: #6a3ff7 0 -12px 6px inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: -.24px;
   
    margin: 0;
    outline: none;
    padding: 1rem 1.3rem;
    quotes: auto;
    text-align: center;
    text-decoration: none;
    transition: all .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
 }

 .contactBtn:hover {
    background-color: #959dec;
    box-shadow: #6715f5 0 -6px 8px inset;
    transform: scale(1.05);
 }

 .contactBtn:active {
    transform: scale(0.1);
 }

 @media (min-width: 768px) {
    .contactBtn {
      font-size: 1rem;
      padding: 0.2rem 1rem;
 
    }
 }
 @media (min-width: 375px) {
   .contactBtn { 
     font-size: 1rem;
     padding: 0.1rem 1.0rem;
     width: 100%;
  
     
   }
}



 .msg {
    border-radius: 15px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    letter-spacing: 0.1rem;
    font-style: italic;
    color: rgb(229, 61, 0);
    font-weight: 600;
    background-color: #fff;
    display: none;
 }


 /* .contactForm-form input:hover,
 textarea:hover {
  
    transform: scale(1.025);
 
   }
  
 .contactForm-form input {
    padding-left: 10px;
 }
*/

/* .Contact .ConatactMid{
   background-color: rgb(14, 12, 10);
} */
 .Contact .ConatactMid .SendQueryForm{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
    height: 100%;  
 }  
 .Contact .ConatactMid .SendQueryForm .contactForm-form{
   width: 55%;
 }

 .Contact .ConatactMid .contactGif{
   width: 100%;
   height: 100%;
 }
 .Contact .ConatactMid .contactGif img{
   width: 100%;
   height: 100%;
 }

 .Contact .contactDetails{
  padding-top: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 50vh;
   
 }
 .Contact .contactDetails .contactInfo{
  
margin-top: 50px;
margin-bottom: 50px;
   display: flex;
  
   justify-content: center;
   text-align: center;
 }

 .Contact .contactDetails ul{
   list-style: none;
 }
 
 .Contact .contactDetails ul li{
  width: 20%;
 }
 @media screen and (max-width: 800px){
  .Contact .contactImage{
  height: 50vh;
  }
  .Contact .contactDetails .contactInfo{
    flex-direction: column;
    padding-left: 0;
    
  }
  .Contact .contactDetails .contactInfo .contactAdd{
  margin: 0;
  }
  .Contact .contactDetails{
    height: 100vh;
  }
  .Contact .contactDetails ul li{
    width: 100%;
    padding: 30px 0px 30px 0px;
 
  }
 }