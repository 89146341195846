.OurClients {
    margin-top: -20px;
}

.OurClients .ourClientHeadImage {
    height: 70vh;
    background: rgb(92, 88, 171);
    background: linear-gradient(90deg, rgb(235, 234, 237) 0%, rgba(119, 108, 239, 0.978) 51%, rgb(70, 76, 192) 100%);

    /* background-image: url(../photos/kisspng-customer-relationship-management-business-company-sold-out-5ac6f5345be055.5354670615229883403763.png);
    background-position: right; */
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    justify-content: space-between;
    align-items: center;


}

/* .OurClients .ourClientHeadImage .ourClientHeadImageTitle{
    width: 50%;
} */
.OurClients .ourClientHeadImage .ourClientHeadImageTitle span {
    font-size: 70px;
    font-weight: 900;
    padding-left: 50px;
    color: white;
    display: flex;


    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.OurClients .ourClientHeadImage img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1000px) {
    .OurClients .ourClientHeadImage {
        flex-direction: column;
    }
    .OurClients .ourClientHeadImage .ourClientHeadImageTitle{
        width: 100%;
    }
    .OurClients .ourClientHeadImage .ourClientHeadImageTitle span{
        font-size: xx-large;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
    }

    .OurClients .ourClientHeadImage span {
        font-size: xxx-large;
        font-weight: 900;
   


    }

    .OurClients .ourClientHeadImage {
        align-items: baseline;
        justify-content: center;
        height: auto;
       
    }
}

.OurClients .ourClientDescription {
    background: linear-gradient(90deg, rgb(242, 241, 244) 0%, rgba(189, 187, 208, 0.978) 51%, rgb(235, 235, 244) 100%);
    width: 100%;
    display: flex;
    padding: 30px 0px 30px 0px;
    justify-content: center;
    color: azure;

}

.ourClientDescription span {
    color: rgba(68, 68, 75, 0.978);
    width: 90%;
   margin-top: 70px;
   margin-bottom: 70px;
    font-size: larger;
 }

.OurClients .ourClientImages {
    padding: 80px 0 80px 0;
}

.OurClients .ourClientImages .row {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OurClients .ourClientImages .row .titOurClinet {
    display: flex;
    justify-content: center;
    font-size: xx-large;
    font-weight: bold;
}

.OurClients .ourClientImages .col-sm-3 {
    height: 200px;
    width: 200px;
    margin: 20px;
    box-shadow: 2px 2px 35px -10px rgb(187, 177, 177);

}

.OurClients .ourClientImages .col-sm-3:hover {
    /* transition: cubic-bezier(0.075, 0.82, 0.165, 1); */
    /* transform: rotateY('angle'); */
    transition: 0.4s ease;
    transform: scale(1.125);
    box-shadow: 0px 5px 45px -10px rgb(155, 119, 119);
    cursor: pointer;
}

.OurClients .ourClientImages .col-sm-3:active {
    transform: scale(2.025);
}

.OurClients .ourClientImages .col-sm-3 img {
    width: 100%;
    height: 100%;
    object-fit:contain;
}
@media screen and (max-width: 767px){
    .ourClientDescription span{
        text-align: justify;
        margin-top: 0;
        width: 100%;
    }
}

@media screen and (max-width:480px) {
    .OurClients .ourClientImages .col-sm-3{
        height: 120px;
        width: 120px;
        margin: 10px;
    }
 
}